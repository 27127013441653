import AppRoutes from './routes/AppRoutes'

function App() {
  return (
    <AppRoutes/>
  );
}

export default App;

//green- #31D490
//blue- #5D94E7
